import React, { useContext } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { FlexRow, StandardButton } from '../globalstyles';
import { PageContext } from '../page';

export const StandardButtons = () => {
  const context = useContext(PageContext);
  const params: any = useParams();

  const goToUrl = (e: any) => {
    window.open(e.target.dataset.url, '_blank', 'noopener noreferrer');
  };

  return (
    <StandardButtonWrap>
      {context.links.propertyhomepage || context.links.propertyselector ? (
        <FlexRow>
          {context.links.propertyhomepage ? (
            <StandardButton
              className="link-button"
              onClick={goToUrl}
              data-url={context.links.propertyhomepage}
            >
              Eiendommens hjemmeside
            </StandardButton>
          ) : null}
          {context.links.propertyselector ? (
            <StandardButton
              className="link-button"
              onClick={goToUrl}
              data-url={context.links.propertyselector}
            >
              Boligvelger
            </StandardButton>
          ) : null}
        </FlexRow>
      ) : null}
      {context.isProject && (
        <StandardButton
          style={{ marginTop: '1em' }}
          data-url={`/${params.id}/prislistevedlegg`}
          onClick={goToUrl}
        >
          Se prisliste
        </StandardButton>
      )}
    </StandardButtonWrap>
  );
};

const StandardButtonWrap = styled.div`
  .link-button {
    width: 50%;
    margin: 0 10px;
  }
`;
