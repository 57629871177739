import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as clipboard from 'clipboard-polyfill/text';
import { Button, ButtonType } from '../globalstyles';
import { Route } from 'react-router-dom';
import { PageContext } from '../page';
import { useAuthContext } from '../hooks/useAuthContext';

export const Approval = (props: {
  estateId: string;
  match: any;
  history: any;
}) => {
  const { loggedIn } = useAuthContext();
  const [copied, setCopied] = useState(false);
  const context = useContext(PageContext);
  const isPreview = props.match.path.split('/').indexOf('preview') > -1;

  const approve = () => {
    props.history.push(`/preview/${props.match.params.id}/godkjennelse`);
  };

  const copy = () => {
    clipboard.writeText(document.location.href);
    setCopied(true);
  };

  const print = () => {
    const url = context?.attachments.find((file: any) => file.is_print)?.url;
    window.open(`/proxy?file=${url}`, '_blank');
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <>
      {copied && <Notification>Lenke er kopiert</Notification>}
      <ApprovalWrap>
        {isPreview && loggedIn && (
          <>
            <Button buttonType={ButtonType.filled} onClick={approve}>
              Godkjenn
            </Button>
            <Button onClick={copy}>Kopier lenke</Button>
          </>
        )}
        <Route
          exact
          path="/:id/:version(\d+)"
          render={() => {
            if (context?.attachments.length > 0) {
              return <Button onClick={print}>Skriv ut</Button>;
            }
          }}
        />
      </ApprovalWrap>
    </>
  );
};

const Notification = styled.div`
  text-align: center;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  left: 0;
  right: 0;
  padding: 0.2em;
`;

const ApprovalWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 2;
  padding: 2em;
  display: flex;
`;
