import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Images } from '../../graphql/types';
import { Portal } from '../../utils/portal';
import { Section } from '../globalstyles';
import { PageContext } from '../page';
import { FlickityGallery, Image } from './flickity';
import { FlexSection, GalleriButton, Close } from './index';

export const FloorPlanGallery = () => {
  const context = useContext(PageContext);
  const estate: any = context.estate;
  var images = null;

  const [showGallery, setGallery] = useState(false);

  const toggleGallery = () => {
    setGallery(!showGallery);
  };

  if (estate?.images && estate?.images.length > 0) {
    images = estate.images
      .filter((image: any) => image.ai_label === 'plantegning' && image.ai_percent > 85)
      .map((img: any) => {
        return {
          id: img.imageId,
          src: `/images?img=${encodeURIComponent(img.url)}`,
          alt: img.imageDescription
        };
      });
  }

  if (images && images.length > 0) {
    if (images.length === 1) {
      return images.map((img: any) => {
        return (
          <ImageLayout key={img.id}>
            <a href={img.src} target="_blank" rel="noreferrer noopener">
              <img src={img.src} alt="" />
            </a>
          </ImageLayout>
        );
      });
    } else {
      return (
        <>
          <FlexSection
            image={images[0]?.src}
            backgroundSize="contain"
            style={{ height: '50vh', minHeight: '500px' }}
          >
            <GalleriButton onClick={toggleGallery} />
          </FlexSection>
          {showGallery && (
            <Portal>
              <Close onClick={toggleGallery} />
              <FlickityGallery images={images} descOpacity="0.5" />
            </Portal>
          )}
        </>
      );
    }
  }

  return null;
};

const ImageLayout = styled.div`
  max-width: var(--maxWidth);
  margin: 2em auto;
  img {
    width: 100%;
    object-fit: contain;
  }
`;
