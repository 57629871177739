export enum EstateTypes {
  Leilighet = 'flat',
  Enebolig = 'detached',
  Rekkehus = 'terraced',
  Tomannsbolig = 'semidetached',
  Hybel = 'bedsit',
  Hytte = 'cottage',
  Hyttetomt = 'leisure_plot',
  Kontor = 'office',
  Næringstomt = 'businessplot',
  Rom = 'houseshare',
  Tomt = 'plot',
  Butikk = 'business',
  Produksjon = 'industrial',
  Lager = 'warehouse',
  Kombinasjonslokale = 'multipurposearea',
  Gårdsbruk = 'farm',
  Bygård = 'multiunits',
  Kjøpesenter = 'shoppingmall',
  Verksted = 'mechshop',
  Garasje = 'garage',
  Hotell = 'hotel',
  Serveringslokale = 'restaurant',
  Undervisning = 'learningfacility',
  Prosjekt = 'project',
  Annenfritid = 'other_leisure',
  Andre = 'other',
  Ukjent = 'unknown'
}

export enum EstateBaseTypes {
  Ukjent = 'unknown',
  IngenGrunntype = 'notSet',
  Bolig = 'detached',
  Fritid = 'leisure',
  Næring = 'business',
  Prosjekt = 'project',
  Tomt = 'plot'
}

export enum Ownership {
  Eiet = 'owned',
  Andel = 'cooprative',
  Aksje = 'stock',
  Obligasjon = 'bond',
  Eierseksjon = 'condominium'
}

export enum EstateStatus {
  Request = 0,
  Preparation = 1,
  ForSale = 2,
  Sold = 3,
  Reserved = 4,
  Archived = 5,
  Expired = 6,
  Terminated = 7
}

export interface ProjectInfo {
  priceFrom: number | null;
  priceTo: number | null;
  totalPriceFrom: number | null;
  totalPriceTo: number | null;
  primaryFrom: number | null;
  primaryTo: number | null;
  usableFrom: number | null;
  usableTo: number | null;
  btaFrom: number | null;
  btaTo: number | null;
  sampleUnit: ProjectUnit | null;

  usableInternalAreaFrom: number | null;
  usableInternalAreaTo: number | null;
  usableEnclosedBalconyAreaFrom: number | null;
  usableEnclosedBalconyAreaTo: number | null;
  usableExternalAreaFrom: number | null;
  usableExternalAreaTo: number | null;
  openAreaFrom: number | null;
  openAreaTo: number | null;
}

export interface ParkingUnit {
  name: string;
  type_name: string;
  estateId: string;
  status_name: string;
  parkingId: string;
  estimatedTax: number;
  taxbase: number;
  length: string;
  level: string;
  width: string;
  price: number;
  fasiliteter: [
    {
      name: string;
      value: string;
    }
  ];
}

export interface ProjectUnit {
  estateId: string;
  address: {
    publicApartmentNumber: string;
  };
  sortApartmentNumber: string;
  apartmentNumber: string;
  priceEstimate: number;
  primaryRoomArea: number;
  usableArea: number;
  purchaseCostsAmount: number;
  rentPrMonth: number;
  descriptionItemAmountOfRooms: number;
  status: EstateStatus;
  floor: number;
  noOfRooms: number;
  noOfBedRooms: number;
  price: {
    totalPrice: number;
    collectiveDebt: number;
    priceSuggestion: number;
    rentPrMonth: number;
    purchaseCostsAmount: number;
    collectiveAssets: number;
    estimatedValue: number;
    totalPriceExclusiveCostsAndDebt: number;
    rentIncludes: string;
    salesCostDescription: string;
  };
  parkingUnits: ParkingUnit[];
  images: [];
  size: {
    grossArea: number;
    primaryRoomArea: number;
    usableArea: number;
    openArea: number | null;
    usableEnclosedBalconyArea: number | null;
    usableExternalArea: number | null;
    usableInternalArea: number | null;
  };
}

export interface IPageContext {
  isPrint: boolean;
  estate: any;
  meta: any;
  isProject: boolean;
  isTvangssalg: boolean;
  projectInfo: ProjectInfo | null;
  match: any;
  history: any;
  created: string | null;
  attachments: any;
  version: number;
  links: {
    propertyhomepage: string | null;
    propertyselector: string | null;
  };
}

export interface IText {
  title: string;
  category: string;
  text: string;
}

export enum SchemaTypes {
  'default' = 1,
  'prosjekt' = 2
}
