import React from 'react';
import styled from 'styled-components';
import { Section, Title } from '../globalstyles';

enum PDFStatus {
  Error = 'error',
  InProgress = 'in_progress',
  Done = 'done',
  Preview = 'preview'
}

export const Documents = (props: { attachments: any; match: any; status: PDFStatus }) => {
  const isPreview = props.match.path.split('/').indexOf('preview') > -1;

  return (
    <>
      <DocumentSection>
        <Content>
          <Title style={{ marginBottom: 0 }}>Vedlegg til salgsoppgaven</Title>

          {props.status === PDFStatus.Error && (
            <p>
              Det skjedde en feil ved generering av vedlegg. Ta kontakt med
              helpdesk@privatmegleren.no
            </p>
          )}
          {props.status === PDFStatus.InProgress && (
            <p>Vedlegget er under produksjon og vil vises her når det er klart</p>
          )}
          {isPreview && (
            <>
              <p>
                Disse vil slås sammen til en pdf. Vennligst kontroller at disse er
                riktige.
              </p>
              {props.attachments
                .filter((fil: any) => !fil.is_print)
                .map((doc: any) => {
                  let url = doc.url;
                  const { hostname } = new URL(url);
                  if (hostname && hostname === 'api.reeltime.no') {
                    url = `/docs?url=${doc.url}`;
                  }
                  return (
                    <div key={doc.url}>
                      <a target="_blank" rel="noopener noreferrer" href={url}>
                        {doc.head}
                      </a>
                    </div>
                  );
                })}
            </>
          )}
          {!isPreview &&
            props?.attachments &&
            Array.isArray(props?.attachments) &&
            props?.attachments?.findIndex((fil: any) => !fil.is_print) > -1 && (
              <>
                {props?.attachments
                  ?.filter((fil: any) => !fil.is_print && fil?.url?.length)
                  ?.map((doc, index) => {
                    let url = doc.url;
                    const { hostname } = new URL(url);
                    if (hostname && hostname === 'api.reeltime.no') {
                      url = `/proxy?file=${doc.url}`;
                    }
                    return (
                      <div key={`link${index}`}>
                        <a target="_blank" rel="noopener noreferrer" href={url}>
                          Last ned vedlegg
                        </a>
                      </div>
                    );
                  })}
              </>
            )}
        </Content>
      </DocumentSection>
      {/*!isPreview && props?.attachments.filter((fil: any) => !fil.is_print)[0] && (
        <iframe
          title="PDF"
          style={{
            width: '100%',
            height: '90vh',
          }}
          key={`doc_${0}`}
          src={`/web/viewer.html?file=${encodeURIComponent(
            `/proxy?file=${
              props.attachments.filter((fil: any) => !fil.is_print)[0].url
            }`
          )}`}
        />
      )*/}
    </>
  );
};

const DocumentSection = styled(Section)`
  background-color: #000;
  height: auto;
  color: #fff;
  a {
    color: #fff;
  }
`;

const Content = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
  text-align: center;
  a {
    overflow-wrap: break-word;
  }
`;
