import React, { useContext } from 'react';
import styled from 'styled-components';
import { IBuildingArea } from './types';
import { BuildingData } from './BuildingData';

interface IArgs {
  areas: IBuildingArea[];
}

export const BuildingAreas = ({ areas }: IArgs) => {
  if (!areas?.length) return null;
  return (
    <>
      {areas?.map((area, index) => {
        const { type, description, data } = area ?? {};
        if (!data?.length) return null;
        return <BuildingData key={`a${index}`} area={area} />;
      })}
    </>
  );
};
