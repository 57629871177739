export enum Brands {
  ABBL = 'ABBL',
  NBBO = 'NBBO',
  MEKLARHUSET = 'Meklarhuset',
  GARANTI = 'GARANTI',
  MeglerenDin = 'Megleren Din',
  Meglerhuset = 'Meglerhuset',
  REMAX = 'REMAX',
  STORHEIA = 'Storheia',
  OSTLI = 'Østli Eiendomsmegling',
  KOBBL = 'KOBBL',
  PROFFMEGLEREN = 'Proffmegleren AS',
  FOSEN = 'Fosen Eiendomsmegling',
  LEGAL = 'Legal eigedomsmekling',
  TEGNEBY = 'Tegneby & Grønnerød'
}

export const getClassName = (brand?: Brands) => {
  if (!brand) return;
  switch (brand) {
    case Brands.TEGNEBY:
      return 'tegneby';
  }
  return brand?.toLowerCase()?.trim()?.replace(' ', '-');
};
