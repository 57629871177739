import { gql } from '@apollo/client';

export const getEstate = gql`
  query getEstate($input: EstateParams) {
    getEstate(input: $input) {
      status
      message
      created
      attachments {
        documentId
        head
        url
        is_print
      }
      meta {
        schema {
          id
          name
        }
        schema_name
        neighbourhood
        has_neighbourhood_statistics
        stars {
          pos
          star
          key
          text
        }
      }
      estate {
        id
        estateId
        heading
        assignmentNum
        ownAssignmentType
        assignmentType
        termText
        partName
        partAbout
        partOrgNumber
        partNumber
        preemptive
        preEmptiveFee
        preEmptiveDeadline
        boardApproval
        accountingBudgeting
        guaranteeFund
        loanTermsCollectiveDebt
        takeoverComment
        propertyTaxAmount
        propertyTaxYear
        valuationTaxComment
        estateJointOwnershipFraction
        changeOfOwnershipFee
        estateJointOwnershipFraction
        statutes
        residentCommitments
        parkingUnits {
          name
          type_name
          estateId
          status_name
          parkingId
          estimatedTax
          taxbase
          length
          level
          width
          price
          fasiliteter {
            name
            value
          }
        }
        address {
          address
          zipcode
          publicApartmentNumber
          street
          municipality
          latitude
          longitude
        }
        brand {
          name
          logo
        }
        links {
          name
          linkType
          url
        }
        contacts {
          contact_type
          firstName
          lastName
          companyName
        }
        ownership
        ownershipDescription
        noOfRooms
        noOfBedRooms
        noOfBathRooms
        primaryValue
        primaryYear
        secondaryValue
        secondaryYear
        constructionYear
        energy_color
        energy_letter
        estateBaseType
        estateType
        estateTypeExternal
        shareJointDebtToDate
        shareJointCapitalDate
        joinCostAfterGracePeriod
        jointDebtDescription
        jointDebtInterest
        farm
        estateSettlementStatusGroup
        estateSettlementStatusGroupDescription
        agent {
          main {
            name
            email
            mobilePhone
            title
          }
          responsible {
            name
            email
            mobilePhone
          }
          assistant {
            name
            email
            mobilePhone
            title
          }
        }
        amountYearsInstallmentFree
        price {
          totalPrice
          totalPriceExclusiveCostsAndDebt
          collectiveDebt
          priceSuggestion
          rentPrMonth
          purchaseCostsAmount
          collectiveAssets
          estimatedValue
          rentIncludes
          salesCostDescription
          communityTax
          communityTaxYear
          yearlySocietyTax
          waterRate
          waterRateYear
          waterRateDescription
        }
        animals
        floor
        size {
          grossArea
          usableArea
          primaryRoomArea
          primaryRoomAreaDescription
          plot_size
          plot_description
          plot_owned
          usableEnclosedBalconyArea
          usableExternalArea
          usableInternalArea
          openArea
        }
        matrikkel {
          gnr
          bnr
        }
        matrikler {
          bnr
          fnr
          gnr
          knr
          snr
          ownPart
        }
        textContent {
          title
          category
          text
        }
        textfields {
          nonMeasurableAreas {
            heading
            text
          }
          valuatorCommentsToArea {
            heading
            text
          }
          fellesarealerOgRettigheter {
            heading
            text
          }
          localAreaName {
            heading
            text
          }
        }
        images {
          ai_label
          ai_percent
          imageId
          imageSequence
          url
          imageDescription
        }
        buildings {
          name
          areas {
            description
            type
            data {
              description
              floor
              size
            }
          }
        }
        projectUnits {
          estateId
          sortApartmentNumber
          apartmentNumber
          priceEstimate
          primaryRoomArea
          usableArea
          purchaseCostsAmount
          rentPrMonth
          descriptionItemAmountOfRooms
          status
          floor
          noOfRooms
          noOfBedRooms
          noOfBathRooms
          parkingUnits {
            name
            type_name
            estateId
            status_name
            parkingId
            taxbase
            estimatedTax
            level
            length
            width
            price
            fasiliteter {
              name
              value
            }
          }
          images {
            ai_label
            ai_percent
            imageId
            imageSequence
            url
            imageDescription
          }
          price {
            totalPrice
            collectiveDebt
            priceSuggestion
            rentPrMonth
            purchaseCostsAmount
            collectiveAssets
            estimatedValue
            totalPriceExclusiveCostsAndDebt
            rentIncludes
          }
          address {
            publicApartmentNumber
          }
          size {
            primaryRoomArea
            usableArea
            grossArea
            usableEnclosedBalconyArea
            usableExternalArea
            usableInternalArea
            openArea
          }
        }
        neighbourhood {
          demographics {
            boligmasse {
              name
              value
            }
          }
          poiGroups {
            name
            pois {
              poiId
              poitypeId
              name
              distances {
                selected
                air
                walk
                drive
                unit
              }
              duration {
                drive
                walk
                unit
              }
            }
          }
          statistics {
            type
            data {
              type
              data {
                type
                data {
                  index
                  type
                  value
                  percent
                }
              }
            }
          }
          nabolag {
            name
            cityName
            recommendedfor {
              name
              score
            }
            ratings {
              name
              score {
                value
              }
            }
          }
        }
      }
    }
  }
`;
