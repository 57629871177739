import { gql } from '@apollo/client';

export const saveEstate = gql`
  mutation Save($input: EstateParams) {
    saveEstate(input: $input) {
      success
      url
      version
    }
  }
`;
