import { useMutation } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';
import { saveEstate } from '../../graphql/mutations/saveEstate';
import { Button, ButtonType, Section, Title } from '../globalstyles';
import { useAuthContext } from '../hooks/useAuthContext';
import { Loader } from '../loader';

export const Approve = (props: { match: any }) => {
  const { loggedIn, data } = useAuthContext();

  const [
    save,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(saveEstate);

  const approve = () => {
    if (loggedIn) {
      save({
        variables: {
          input: {
            estateId: props.match.params.id,
            agentId: data?.id_ansatte,
          },
        },
      });
    }
  };

  const goto = () => {
    if (mutationData?.saveEstate.success) {
      window.open(mutationData.saveEstate.url, '_blank', 'noopener noreferrer');
    }
  };

  if (!loggedIn) {
    return <div>Ikke logget inn</div>;
  }

  return (
    <>
      {mutationLoading && <Loader text="Lager salgsoppgave. . ." />}
      {mutationError && <StatusBar>Det skjedde en feil</StatusBar>}
      {mutationData?.saveEstate.success ? (
        <ApprovalSection>
          <Title>Salgsoppgaven er godkjent</Title>
          <Content>
            <p>Du finner den digitale salgsoppgaven under lenker i NEXT</p>
            <Button onClick={goto} type="button" buttonType={ButtonType.filled}>
              Gå til salgsoppgave
            </Button>
          </Content>
        </ApprovalSection>
      ) : (
        <ApprovalSection>
          <Title>Godkjennelse av salgsoppgave</Title>
          <Content>
            <p>
              Salgsoppgave vil bli tilgjengelig i autoprospekt som sendes til
              interessent per e-post.
            </p>
            <p>
              NB! Dersom viktige endringer gjøres i Vitec Next, må du godkjenne
              og publisere en ny utgave.
            </p>
            <Button
              onClick={approve}
              type="button"
              buttonType={ButtonType.filled}
            >
              Publiser salgsoppgave
            </Button>
          </Content>
        </ApprovalSection>
      )}
    </>
  );
};

const ApprovalSection = styled(Section)`
  background-color: #000;
  color: #fff;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const StatusBar = styled.div`
  background: #fff;
  padding: 1em 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;
