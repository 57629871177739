import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { KeyInfoData } from './keyinfoData';
import { Section, Title } from '../globalstyles';
import { PageContext } from '../page';
import { FloorPlanGallery } from '../gallery/floorplan';

export const KeyInformation = () => {
  const context = useContext(PageContext);
  const estate: any = context.estate;

  return (
    <InfoSection>
      <InfoContent>
        <Title>Nøkkelinformasjon</Title>
        <div>
          <KeyInfoData />
        </div>
      </InfoContent>
      {!context.isPrint && !context.isProject ? <FloorPlanGallery /> : null}
    </InfoSection>
  );
};

const InfoSection = styled(Section)`
  height: auto;
`;

const InfoContent = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr {
    cursor: pointer;
  }

  th,
  td {
    border-bottom: 1px solid #000;
    padding: 10px;
    text-align: left;
  }
`;
