import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { getEstate } from '../graphql/queries/getEstate';
import { Agent } from './agent';
import { BGColor, Content } from './content';
import { Documents } from './documents';
import { Facts } from './facts';
import { Loader } from './loader';
import { FrontPage } from './frontpage';
import { Gallery } from './gallery';
import { GiBud } from './gibud';
import { Heading } from './heading';
import { KeyInformation } from './keyinfo';
import { Map } from './map';
import { aggregateProjectData } from './utils/aggregateProjectData';
import { Nabolagsprofil } from './nabolagsprofil';

import { EstateTypes, IPageContext, ProjectInfo } from './types';
import { getVilkaar } from './utils/getVilkaar';
import { FrontSection, LoadingText, StandardButton } from './globalstyles';
import { useAuthContext } from './hooks/useAuthContext';
import { useParams } from 'react-router-dom';
import { LogoLite } from './ui/LogoLite';
import { getClassName } from './utils/brands';

export const PageContext = React.createContext<IPageContext>({
  isPrint: false,
  estate: null,
  meta: null,
  isProject: false,
  isTvangssalg: false,
  projectInfo: null,
  match: null,
  history: null,
  created: null,
  attachments: [],
  version: 0,
  links: {
    propertyhomepage: null,
    propertyselector: null
  }
});

export const Page = (props: { match: any; history: any; staticContext: any }) => {
  const { loggedIn } = useAuthContext();
  const [isPrint, setIsPrint] = useState(false);
  const params = useParams<{ version: string }>();

  useEffect(() => {
    const isPrint = props.history.location.search === '?print=true';
    if (isPrint) {
      setIsPrint(true);
    }
  }, []);

  const goToPricelist = () => {
    window.open(
      `/${props.match.params.id}/prislistevedlegg`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const version = parseInt(props.match.params.version, 10);
  const { data, loading, error } = useQuery(getEstate, {
    variables: {
      input: {
        estateId: props.match.params.id,
        version,
        create_preview: false,
        doRefresh: loggedIn && !params?.version
      }
    }
  });

  useEffect(() => {
    const className = getClassName(data?.getEstate?.estate?.brand?.name);
    if (className && document) {
      document.body.classList.add(className);
    }
  }, [data]);

  if (error) {
    return (
      <div>
        Det skjedde en feil. Prøv igjen senere eller ta kontakt med helpdesk@reeltime.no
      </div>
    );
  }
  if (loading) {
    return <Loader text="Henter informasjon om eiendommen. . ." />;
  }
  const estate = data.getEstate.estate;
  const meta = data.getEstate.meta;
  const attachments = data.getEstate.attachments;
  const created = data.getEstate.created;
  const status = data.getEstate.status;
  const message = data.getEstate?.message;

  // hent linkene de spesifikt skal ha (hjemmeside og boligvelger)
  const links = estate?.links?.reduce((obj: any, link: any) => {
    if (link.linkType === 3) {
      if (link.name.toLowerCase() === 'boligvelger') {
        obj.propertyselector = link.url;
      }
      if (link.name.toLowerCase() === 'hjemmeside') {
        obj.propertyhomepage = link.url;
      }
    }
    return obj;
  }, {});

  if (status === 'blocked') {
    return (
      <FrontSection>
        <LoadingText>
          Bestilling av salgsoppgave er ikke mulig for denne eiendommen
        </LoadingText>
      </FrontSection>
    );
  }

  if (status === 'error') {
    return (
      <FrontSection>
        <LoadingText>
          {message && message?.trim()?.length > 0
            ? message
            : `Det skjedde en uforutsett feil`}
        </LoadingText>
      </FrontSection>
    );
  }

  if (!estate) {
    if (props.staticContext) {
      props.staticContext.status = 404;
    }
    return null;
  }

  let isProject = false;
  let isTvangssalg = false;
  let projectInfo: ProjectInfo = {
    priceFrom: null,
    priceTo: null,
    totalPriceFrom: null,
    totalPriceTo: null,
    primaryFrom: null,
    primaryTo: null,
    usableFrom: null,
    usableTo: null,
    btaFrom: null,
    btaTo: null,
    sampleUnit: null,
    usableInternalAreaFrom: null,
    usableInternalAreaTo: null,
    usableEnclosedBalconyAreaFrom: null,
    usableEnclosedBalconyAreaTo: null,
    usableExternalAreaFrom: null,
    usableExternalAreaTo: null,
    openAreaFrom: null,
    openAreaTo: null
  };
  if (estate.estateBaseType === EstateTypes.Prosjekt) {
    isProject = true;
    if (estate.projectUnits && estate.projectUnits.length > 0) {
      projectInfo = aggregateProjectData(estate.projectUnits);
    }
  }
  if (estate.assignmentType === 'foreclosure') {
    isTvangssalg = true;
  }

  return (
    <>
      <PageContext.Provider
        value={{
          estate: estate,
          meta,
          isPrint: isPrint,
          isProject: isProject,
          isTvangssalg: isTvangssalg,
          projectInfo: projectInfo,
          match: props.match,
          history: props.history,
          created: created,
          attachments: attachments,
          version: version,
          links: links
        }}
      >
        <FrontPage />
        <Gallery />
        <LogoLite brand={estate?.brand?.name} />
        <Heading />
        <Agent />
        {!isProject ? (
          <GiBud isTvangssalg={isTvangssalg} brand={estate?.brand?.name} />
        ) : null}
        <KeyInformation />
        {estate.address.latitude && estate.address.longitude ? (
          <Map latitude={estate.address.latitude} longitude={estate.address.longitude} />
        ) : null}
        <Facts />
        {/** mulig vi fjerner prisliste herfra ja */}
        <div style={{ margin: '2em 0' }}>
          {isProject ? (
            <StandardButton onClick={goToPricelist}>Se prislisten</StandardButton>
          ) : null}
        </div>
        <LogoLite brand={estate?.brand?.name} />
        <Nabolagsprofil />
        <Content
          title={isTvangssalg ? 'Tvangssalg' : 'Vilkår'}
          bgColor={BGColor.secondary}
          className="terms"
        >
          {estate.termText ? (
            <div dangerouslySetInnerHTML={{ __html: estate.termText }} />
          ) : (
            getVilkaar(estate.ownership)
          )}
        </Content>
        {!isProject ? (
          <GiBud isTvangssalg={isTvangssalg} brand={estate?.brand?.name} />
        ) : null}
        {!isPrint ? (
          <Documents status={status} attachments={attachments} match={props.match} />
        ) : null}
      </PageContext.Provider>
    </>
  );
};
