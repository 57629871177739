import React from 'react';
import styled from 'styled-components';

interface Rating {
  name: string;
  score: {
    value: number;
  };
}

// Bruke opplevd trygghet, naboskap, kvalitet på skolene, turmulighetene, støynivået, trafikk

export const Ratings = (props: { ratings: Rating[] }) => {
  return (
    <RatingsWrapper>
      {props.ratings.slice(0, 3).map((rating: Rating) => {
        return (
          <div key={rating.name}>
            <RatingTitle>{rating.name}</RatingTitle>
            <div>{`${Math.round(rating.score.value * 100)}/100`}</div>
          </div>
        );
      })}
    </RatingsWrapper>
  );
};

const RatingsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 2em;
  text-align: center;

  @media (min-width: 800px) {
    text-align: left;
  }
`;

const RatingTitle = styled.div`
  font-size: 1.2em;

  @media (min-width: 800px) {
    font-size: 1.6em;
  }
`;
