import React, { useContext } from 'react';
import { Section, StandardButton } from '../globalstyles';
import { PageContext } from '../page';
import { Brands } from '../utils/brands';

interface IProps {
  isTvangssalg: boolean;
  brand?: Brands | null;
}

export const GiBud = ({ isTvangssalg, brand }: IProps) => {
  const context = useContext(PageContext);
  const goTo = () => {
    if (isTvangssalg) {
      let key: string = `616589f7-e7f8-41b3-86f3-cb81c95ee1ce`;
      switch (brand) {
        case Brands.LEGAL:
          key = `f91bb98e-d0f1-4088-a8f2-a61c460d4581`;
          break;
        case Brands.STORHEIA:
          key = `f8122aa8-d781-4322-b987-8c415a5209fe`;
          break;
      }
      if (!key?.length) return;
      window.open(
        `https://digitalsalgsoppgave.garanti.no/api/file/view/${key}`,
        '_blank',
        'noopener noreferrer'
      );
    } else {
      window.open(
        `https://bud.vitecnext.no/?Installationid=MSGAR&Estateid=${context.estate.estateId}`,
        '_blank',
        'noopener noreferrer'
      );
    }
  };

  return (
    <Section style={{ height: 'auto' }}>
      <StandardButton onClick={goTo}>Gi bud</StandardButton>
    </Section>
  );
};
