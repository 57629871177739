import { useEffect, useState } from 'react';

export interface IUseLoggedInWithLoadingData {
  id_ansatte: string;
  id_oppdrag: string;
  valid: boolean;
}

export const useLoggedInWithLoading = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoggedIn, setLoggedIn] = useState<any>(false);
  const [data, setData] = useState<IUseLoggedInWithLoadingData | undefined>(
    undefined
  );

  useEffect(() => {
    const authRequest = async () => {
      try {
        const response = await fetch('/preview/isloggedin', {
          credentials: 'include',
        });
        const json = await response.json();
        if (json?.loggedIn) {
          setLoggedIn(true);
          if (json?.data) setData(json.data);
        } else {
          setLoggedIn(false);
          setData(undefined);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    authRequest();
  }, []);

  return [isLoggedIn, { data, loading }];
};
