import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

declare const L: any;

export const Map = (props: { latitude: string; longitude: string }) => {
  const mapRef = useRef(null);
  const [mapInit, setMapInit] = useState(false);

  useEffect(() => {
    if (mapRef?.current && props.latitude && props.longitude) {
      const map = L.map(mapRef.current, {
        keyboard: false,
        dragging: false,
        zoomControl: false,
        boxZoom: false,
        doubleClickZoom: false,
        scrollWheelZoom: false,
        tap: false,
        touchZoom: false,
        center: [51.505, -0.09],
        zoom: 18,
        minZoom: 18,
        maxZoom: 18
      }).setView([props.latitude, props.longitude], 18);
      L.tileLayer(
        'https://cache.kartverket.no/v1/wmts/1.0.0/topograatone/default/webmercator/{z}/{y}/{x}.png',
        {
          attribution: '<a href="http://www.kartverket.no/">Kartverket</a>'
        }
      ).addTo(map);
      L.marker([props.latitude, props.longitude]).addTo(map);
    }
  }, [mapRef]);

  return <MapDiv ref={mapRef} />;
};

const MapDiv = styled.div`
  width: 100%;
  height: 600px;
  z-index: 1;
`;
