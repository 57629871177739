import React from 'react';
import { Oppfordring } from './oppfordring';

export const FastEiendom = () => {
  return (
    <div>
      <p>
        Kjøper man fast eiendom er det selve grunnen med påstående bebyggelse
        man kjøper. Om fast eiendom sies det ofte at grunneieren har fri
        rådighet over eiendommen innenfor de begrensninger som følger av
        gjeldende lovgivning og andres særlige rettigheter til bruk av grunnen.
        Ved at eiendomsretten omfatter de rådighetsformer som ikke er positivt
        unntatt ved lov eller særliger rettigheter for andre enn eieren, sies
        det ofte at retten er negativt begrenset.
      </p>
      <p>
        Opplysninger som fremgår i denne salgsoppgaven er i hovedsak gitt av
        nåværende eier. Megler har også innhentet informasjon fra andre
        tilgjengelige kilder. Interessenter oppfordres til selv å foreta
        undersøkelser som kan ha betydning for eventuelle handlingsvalg
        forbundet med bud/kjøp av eiendommen.
      </p>
      <Oppfordring />
    </div>
  );
};
