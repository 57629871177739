import { gql } from "@apollo/client";

export const getPricelist = gql`
  query getPricelist($input: EstateParams) {
    getEstate(input: $input) {
      estate {
        address {
          address
        }
        parkingUnits {
          name
          type_name
          estateId
          status_name
          parkingId
          estimatedTax
          taxbase
          length
          level
          width
          price
          fasiliteter {
            name
            value
          }
        }
        projectUnits {
          estateId
          sortApartmentNumber
          apartmentNumber
          priceEstimate
          primaryRoomArea
          usableArea
          purchaseCostsAmount
          rentPrMonth
          descriptionItemAmountOfRooms
          status
          floor
          noOfRooms
          noOfBedRooms
          parkingUnits {
            name
            type_name
            estateId
            status_name
            parkingId
            taxbase
            estimatedTax
            level
            length
            width
            price
            fasiliteter {
              name
              value
            }
          }
          images {
            ai_label
            ai_percent
            imageId
            imageSequence
            url
            imageDescription
          }
          price {
            totalPrice
            collectiveDebt
            priceSuggestion
            rentPrMonth
            purchaseCostsAmount
            collectiveAssets
            estimatedValue
            totalPriceExclusiveCostsAndDebt
            rentIncludes
          }
          address {
            publicApartmentNumber
          }
          size {
            primaryRoomArea
            usableArea
            grossArea
            usableEnclosedBalconyArea
            usableExternalArea
            usableInternalArea
            openArea
          }
        }
      }
    }
  }
`;
