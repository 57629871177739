export const epIcons = `
[class*=' ep-nlp_'],
[class^='ep-nlp_'] {
  font-family: 'ep' !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: left;
  font-size: 1.8em;
  margin: -5px 3px 0 0;
}

.ep-nlp_activities:before {
  content: '\\e905';
}

.ep-nlp_adventure:before {
  content: '\\e902';
}

.ep-nlp_air-distance:before {
  content: '\\e61f';
}

.ep-nlp_airplane:before,
.ep-nlp_airplane_regional:before {
  content: '\\e600';
}

.ep-nlp_airport_bus:before {
  content: '\\e907';
}

.ep-nlp_airport_train:before {
  content: '\\e906';
}

.ep-nlp_arrow-left:before {
  content: '\\e614';
}

.ep-nlp_arrow-right:before {
  content: '\\e613';
}

.ep-nlp_attraction:before {
  content: '\\e901';
}

.ep-nlp_back:before {
  content: '\\e611';
}

.ep-nlp_bank:before {
  content: '\\e909';
}

.ep-nlp_beach:before {
  content: '\\e908';
}

.ep-nlp_bike:before {
  content: '\\e900';
}

.ep-nlp_buss:before,
.ep-nlp_transport:before {
  content: '\\e62c';
}

.ep-nlp_cities:before {
  content: '\\e60e';
}

.ep-nlp_coffee:before {
  content: '\\e60d';
}

.ep-nlp_compass:before {
  content: '\\e60a';
}

.ep-nlp_drive:before {
  content: '\\e625';
}

.ep-nlp_family:before {
  content: '\\e608';
}

.ep-nlp_ferry_wharf:before {
  content: '\\e607';
}

.ep-nlp_food_nightlife:before {
  content: '\\e904';
}

.ep-nlp_gas-station:before {
  content: '\\e605';
}

.ep-nlp_grocery-store:before {
  content: '\\e606';
}

.ep-nlp_gym:before {
  content: '\\e604';
}

.ep-nlp_hotel:before {
  content: '\\e62b';
}

.ep-nlp_kindergarden:before {
  content: '\\e603';
}

.ep-nlp_kiosk-video:before {
  content: '\\e629';
}

.ep-nlp_lifestyle:before {
  content: '\\e609';
}

.ep-nlp_light-rail:before {
  content: '\\e627';
}

.ep-nlp_living-environment:before {
  content: '\\e623';
}

.ep-nlp_marker:before {
  content: '\\e633';
}

.ep-nlp_marker_fill:before {
  content: '\\e632';
}

.ep-nlp_nature_area:before {
  content: '\\e621';
}

.ep-nlp_nightlife-food:before {
  content: '\\e62f';
}

.ep-nlp_parkering:before {
  content: '\\e620';
}

.ep-nlp_people:before {
  content: '\\e61d';
}

.ep-nlp_pharmacy:before {
  content: '\\e61e';
}

.ep-nlp_places:before {
  content: '\\e61c';
}

.ep-nlp_posten:before {
  content: '\\e61a';
}

.ep-nlp_elementary-school:before,
.ep-nlp_primary-school:before {
  content: '\\e601';
}

.ep-nlp_quotes:before {
  content: '\\e62a';
}

.ep-nlp_high-school:before,
.ep-nlp_school:before {
  content: '\\e602';
}

.ep-nlp_shopping-mall:before,
.ep-nlp_shopping:before {
  content: '\\e61b';
}

.ep-nlp_ski-lift:before {
  content: '\\e618';
}

.ep-nlp_sports-facilities:before {
  content: '\\e615';
}

.ep-nlp_subway2 .path1:before {
  content: '\\e90b';
  color: #5c5c5c;
}

.ep-nlp_subway2 .path2:before {
  content: '\\e90c';
  margin-left: -1.333984375em;
  color: #fff;
}

.ep-nlp_summary:before {
  content: '\\e62e';
}

.ep-nlp_taxi:before {
  content: '\\e62d';
}

.ep-nlp_thumb:before {
  content: '\\e626';
}

.ep-nlp_airport-train:before,
.ep-nlp_central-station:before,
.ep-nlp_train:before {
  content: '\\e610';
}

.ep-nlp_vinmonopol:before {
  content: '\\e630';
}

.ep-nlp_walking:before {
  content: '\\e631';
}

.ep-nlp_winter_sport:before {
  content: '\\e903';
}

.ep-nlp_subway:before {
  content: '\\e616';
}

.ep-nlp_car-charger:before {
  content: '\\e90a';
}

.ep-nlp_ski-downhill:before {
  content: '\\e90d';
}

.ep-nlp_ski-lift2:before {
  content: '\\e90e';
}

.ep-nlp_ski-crosscountry:before {
  content: '\\e90f';
}

.ep-nlp_kollektivknutepunkt:before {
  content: '\\e910';
}

`;
