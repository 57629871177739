import React, { useContext } from 'react';
import styled from 'styled-components';
import { ValidData } from '../../../../utils/utils';
import { FlexRow, Row } from '../../../globalstyles';

interface CadasterItem {
  bnr?: number;
  fnr?: number;
  gnr?: number;
  knr?: number;
  snr?: number;
  ownPart?: string;
}

interface IProps {
  matrikkel?: CadasterItem;
  matrikler?: CadasterItem[];
}

export const Cadasters: React.FC<IProps> = ({ matrikkel, matrikler }) => {
  const list = matrikler?.filter((e) => e?.bnr || e?.fnr || e?.gnr || e?.knr || e?.snr);
  if (list?.length && list?.length > 1) {
    return (
      <>
        {list?.map(({ bnr, fnr, gnr, knr, snr, ownPart }, index) => (
          <Row key={`cadaster${index}`} style={{ marginTop: '1.5em' }}>
            <div>{`Matrikkel ${index + 1}`}</div>
            <StyledCadaster>
              {gnr && ValidData(gnr) ? (
                <>
                  <StyledDiv>Gårdsnummer:</StyledDiv>
                  <StyledDiv>{gnr}</StyledDiv>
                </>
              ) : null}
              {bnr && ValidData(bnr) ? (
                <>
                  <StyledDiv>Bruksnummer:</StyledDiv>
                  <StyledDiv>{bnr}</StyledDiv>
                </>
              ) : null}
              {knr && ValidData(knr) ? (
                <>
                  <StyledDiv>Kommunenummer:</StyledDiv>
                  <StyledDiv>{knr}</StyledDiv>
                </>
              ) : null}
              {fnr && ValidData(fnr) ? (
                <>
                  <StyledDiv>Festenummer:</StyledDiv>
                  <StyledDiv>{fnr}</StyledDiv>
                </>
              ) : null}
              {snr && ValidData(snr) ? (
                <>
                  <StyledDiv>Seksjonsnummer:</StyledDiv>
                  <StyledDiv>{snr}</StyledDiv>
                </>
              ) : null}
              {ownPart?.length ? (
                <>
                  <StyledDiv>Eierandel:</StyledDiv>
                  <StyledDiv>{ownPart}</StyledDiv>
                </>
              ) : null}
            </StyledCadaster>
          </Row>
        ))}
      </>
    );
  }

  if (!matrikkel) return null;
  return (
    <>
      {matrikkel?.gnr && ValidData(matrikkel?.gnr) ? (
        <Row>
          <div>Gårdsnummer</div>
          <div>{matrikkel.gnr}</div>
        </Row>
      ) : null}
      {matrikkel?.bnr && ValidData(matrikkel?.bnr) ? (
        <Row>
          <div>Bruksnummer</div>
          <div>{matrikkel.bnr}</div>
        </Row>
      ) : null}
    </>
  );
};

const StyledCadaster = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: min-content;
  grid-column-gap: 0.5em;
`;

const StyledDiv = styled.div`
  font-weight: normal !important;
`;
