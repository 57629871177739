import React from 'react';

export const Oppfordring = () => {
  return (
    <>
      <p>
        <strong>Det oppfordres spesielt til å:</strong>
      </p>
      <ul>
        <li>
          Besiktige eiendommen nøye, gjerne sammen med bygningskyndig person.
        </li>
        <li>Undersøke vann- og kloakkforhold med kommunen.</li>
        <li>Undersøke reguleringsforhold med kommunen.</li>
        <li>Undersøke sluk/avløp på bad.</li>
      </ul>

      <p>
        Interessenter som mottar informasjon som oppfattes ikke å sammenfalle
        med informasjonen gitt i salgsoppgaven oppfordres til å ta dette opp med
        megler snarest.
      </p>
      <p>
        <strong>"As is"</strong>
        <br /> Eiendommen selges i den stand den var ved kjøpers besiktigelse.
        evt. da kjøper kunne ha besiktiget eiendommen (as is), jfr.
        Avhendingsloven § 3-9.
      </p>
      <p>Eiendommen har derfor bare mangel i følgende tilfeller:</p>
      <ul>
        <li>
          Når kjøper ikke har fått opplysninger om forhold ved eiendommen som
          selger kjente eller måtte kjenne til, og som kjøper hadde grunn til å
          regne med å få. Dette gjelder likevel bare dersom man kan gå ut fra at
          det har virket inn på avtalen at opplysningene ikke ble gitt.
        </li>
        <li>
          Når selger har gitt uriktige opplysninger om eiendommen. Dette gjelder
          likevel bare dersom man kan gå ut fra at opplysningene har virket inn
          på avtalen, eller ikke er blitt rettet i tide. Når eiendommen er i
          vesentlig dårligere stand enn kjøper hadde grunn til å regne med ut
          fra kjøpesummen og forholdene ellers.
        </li>
        <li>
          Når eiendommens tomt har et vesentlig mindre areal enn opplyst av
          selgeren. Det forutsettes at skjøtet tinglyses på ny eier. Dersom
          kjøper ikke ønsker at skjøtet skal tinglyses, må det tas spesielt
          forbehold om dette i bud. I de tilfeller hvor kjøpers kreditor krever
          forsikring/garantistillelse fra megler utover de krav som stilles i
          lov om eiendomsmegling må kjøper dekke eventuelle kostnader forbundet
          med dette.
        </li>
      </ul>
    </>
  );
};
