import React, { useContext } from 'react';
import { IPoiGroup, IPoi } from '.';
import { Content, Section } from '../globalstyles';
import styled from 'styled-components';
import { PageContext } from '../page';

const icons: any = {
  110: 'cities',
  210: 'airplane',
  211: 'airplane_regional',
  220: 'train',
  221: 'central-station',
  222: 'subway',
  223: 'train',
  230: 'light-rail',
  235: 'kollektivknutepunkt',
  240: 'buss',
  241: 'buss',
  250: 'ferry_wharf',
  260: 'taxi',
  270: 'car-charger',
  280: 'bike',
  310: 'sports-facilities',
  330: 'gym',
  350: 'activities',
  355: 'ski-lift',
  360: 'ferry_wharf',
  401: 'elementary-school',
  402: 'elementary-school',
  500: 'high-school',
  550: 'high-school',
  551: 'high-school',
  600: 'kindergarden',
  700: 'shopping-mall',
  800: 'grocery-store',
  900: 'pharmacy',
  1000: 'vinmonopol',
  1100: 'grocery-store',
  1200: 'grocery-store',
  1300: 'gas-station',
  2500: 'parkering'
};

const type: any = {
  air: 'poi-icon ep-nlp_airplane',
  walk: 'poi-icon ep-nlp_walking',
  drive: 'poi-icon ep-nlp_drive'
};

const getIcon = (poitypeid: any) => {
  const ikonid = poitypeid;
  if (icons[ikonid]) {
    return <div className={`poi-icon ep-nlp_${icons[ikonid]}`} />;
  }
  return null;
};

export const PoiList = () => {
  const context = useContext(PageContext);

  const poi = context.estate.neighbourhood.poiGroups;
  if (!poi) return null;

  return (
    <PoiSection color="#EDECE8">
      <Content>
        {poi.map((poigroup: IPoiGroup, i: number) => {
          return (
            <div key={`${poigroup.name}_${i}`}>
              <PoiTitle>{poigroup.name}</PoiTitle>
              {poigroup.pois.map((p: IPoi, i: number) => (
                <Poi key={p.poiId}>
                  <div>
                    {getIcon(p.poitypeId)}
                    {p.name}
                  </div>

                  <DistanceDuration>
                    {p.distances && (
                      <div>
                        {p.distances[p.distances.selected]} {p.distances.unit}
                      </div>
                    )}
                    <div>
                      {p.duration && (
                        <>
                          {p.distances.selected &&
                            Math.round(p.duration[p.distances.selected] / 60)}{' '}
                          min
                        </>
                      )}
                      <div className={type[p.distances.selected]}></div>
                    </div>
                  </DistanceDuration>
                </Poi>
              ))}
            </div>
          );
        })}
      </Content>
    </PoiSection>
  );
};

const PoiSection = styled(Section)`
  min-height: 100vh;
  height: auto;
  background-color: var(--neighborhood-poi-background-color, #edece8);
`;

const PoiTitle = styled.div`
  font-size: 1.4em;
  margin: 1em 0;
`;

const Poi = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c3c3c0;
  padding: 10px 0 6px;
  line-height: 1.5;
`;

const DistanceDuration = styled.div`
  display: flex;
  div:first-child {
    margin: 0 10px 0 0;
  }

  .poi-icon {
    float: right;
    top: -5px;
    position: relative;
    left: 2px;
  }
`;
