import { apartmentNumberComparator } from '../keyinfo/apartmentNumberComparator';
import { EstateStatus, ProjectInfo, ProjectUnit } from '../types';

export const aggregateProjectData = (projectUnits: any[]): ProjectInfo => {
  const sortableList = [...projectUnits];
  const filterStatus = [EstateStatus.Sold, EstateStatus.Request];
  const notSold = sortableList.filter(
    (unit: ProjectUnit) => !filterStatus.includes(unit.status)
  );
  const minMaxPrice: any = notSold.sort((a: ProjectUnit, b: ProjectUnit) => {
    if (a.priceEstimate > b.priceEstimate) return -1;
    if (a.priceEstimate < b.priceEstimate) return 1;
    return 0;
  });
  const priceFrom = minMaxPrice[minMaxPrice.length - 1].priceEstimate;
  const priceTo = minMaxPrice[0].priceEstimate;

  const minMaxTotalPrice: any = notSold
    .filter((f: ProjectUnit) => f.price.totalPrice)
    .sort((a: ProjectUnit, b: ProjectUnit) => {
      if (a.price.totalPrice > b.price.totalPrice) return -1;
      if (a.price.totalPrice < b.price.totalPrice) return 1;
      return 0;
    });
  const totalPriceFrom = minMaxTotalPrice[minMaxTotalPrice.length - 1].price.totalPrice;
  const totalPriceTo = minMaxTotalPrice[0].price.totalPrice;

  const minMaxPrimary: number[] = notSold.map<number>(
    ({ primaryRoomArea }: ProjectUnit) => primaryRoomArea
  );

  const minMaxUsable: number[] = notSold.map<number>(
    ({ usableArea }: ProjectUnit) => usableArea
  );

  const minMaxGross: number[] = notSold.map<number>(
    ({ size }: ProjectUnit) => size?.grossArea ?? 0
  );

  const minMaxUsableInternal: number[] = notSold.map<number>(
    ({ size }: ProjectUnit) => size?.usableInternalArea ?? 0
  );

  const minMaxUsableExternal: number[] = notSold.map<number>(
    ({ size }: ProjectUnit) => size?.usableExternalArea ?? 0
  );

  const minMaxUsableEnclosedBalconyArea: number[] = notSold.map<number>(
    ({ size }: ProjectUnit) => size?.usableEnclosedBalconyArea ?? 0
  );

  const minMaxOpenArea: number[] = notSold.map<number>(
    ({ size }: ProjectUnit) => size?.openArea ?? 0
  );

  const sampleUnit: any = notSold
    .filter((unit: ProjectUnit) => unit.sortApartmentNumber)
    .sort(apartmentNumberComparator)[0];

  return {
    priceFrom,
    priceTo,
    totalPriceFrom,
    totalPriceTo,
    primaryFrom: getValue(minMaxPrimary),
    primaryTo: getValue(minMaxPrimary, 'max'),
    usableFrom: getValue(minMaxUsable),
    usableTo: getValue(minMaxUsable, 'max'),
    btaFrom: getValue(minMaxGross),
    btaTo: getValue(minMaxGross, 'max'),
    sampleUnit: sampleUnit,
    usableInternalAreaFrom: getValue(minMaxUsableInternal),
    usableInternalAreaTo: getValue(minMaxUsableInternal, 'max'),
    usableExternalAreaFrom: getValue(minMaxUsableExternal),
    usableExternalAreaTo: getValue(minMaxUsableExternal, 'max'),
    usableEnclosedBalconyAreaFrom: getValue(minMaxUsableEnclosedBalconyArea),
    usableEnclosedBalconyAreaTo: getValue(minMaxUsableEnclosedBalconyArea, 'max'),
    openAreaFrom: getValue(minMaxOpenArea),
    openAreaTo: getValue(minMaxOpenArea, 'max')
  };
};

const getValue = (values: number[], type: 'min' | 'max' = 'min') => {
  if (!Array.isArray(values) || !values?.length) return null;
  const value = type === 'min' ? Math.min(...values) : Math.max(...values);
  if (isNaN(value)) return null;
  if (!isFinite(value)) return null;
  return value || null;
};
