import React from 'react';
import { Oppfordring } from './oppfordring';

export const Kontraktsposisjon = () => {
  return (
    <div>
      <p>
        De opplysninger som fremgår av denne salgsoppgave er i hovedsak gitt av
        byggherre og fra andre tilgjengelige kilder. Selv om megler har oversikt
        over de forhold som er av betydning, og som er tilgjengelig via
        relevante registre og arkiver, har interessenter anledning til selv å
        foreta de undersøkelser som de mener er vesentlige for eventuelle
        bud/kjøp av eiendommen.
      </p>
      <p>
        <strong>Herunder f.eks</strong>
      </p>
      <ul>
        <li>Besiktige dokumentene</li>
        <li>Undersøke vann- og kloakkfohold (teknisk etat i kommunen).</li>
        <li>Undersøke reguleringsforhold (teknisk etat i kommunen).</li>
      </ul>
    </div>
  );
};
