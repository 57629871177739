import React from 'react';
import {
  IUseLoggedInWithLoadingData,
  useLoggedInWithLoading,
} from '../hooks/useLoggedInWithLoading';
import { Loader } from '../loader';

export const AuthContext = React.createContext<{
  loggedIn: boolean;
  data: IUseLoggedInWithLoadingData | undefined;
}>({
  loggedIn: false,
  data: undefined,
});

export const AuthContextProvider: React.FC = ({ children }) => {
  const [loggedIn, { data, loading }] = useLoggedInWithLoading();

  if (loading) return <Loader text="Henter informasjon om eiendommen. . ." />;

  return (
    <AuthContext.Provider value={{ loggedIn: !!loggedIn, data }}>
      {children}
    </AuthContext.Provider>
  );
};
