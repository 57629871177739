import React from 'react';
import styled from 'styled-components';
import { FrontSection } from './globalstyles';

export const Front = () => {
  return (
    <FrontSection>
      <img src="/static/garanti-logo-stor-svart.png" width="300" />
    </FrontSection>
  );
};
