import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { currency } from '../../utils/currency';
import {
  ExtraInfo,
  ExtraWrap,
  Section,
  SubUnitWrap,
  Table,
  Title,
} from '../globalstyles';
import { PageContext } from '../page';
import { ParkingUnit } from '../types';

const Info = (props: { parkingUnit: ParkingUnit }) => {
  return (
    <SubUnitWrap>
      <td style={{ backgroundColor: '#e6e7e2' }} colSpan={4}>
        <ExtraWrap>
          <ExtraInfo>
            <strong>Informasjon om plassen</strong>

            {props.parkingUnit.name && (
              <div>Plass-navn: {props.parkingUnit.name}</div>
            )}
            {props.parkingUnit.type_name && (
              <div>Type: {props.parkingUnit.type_name}</div>
            )}
            {props.parkingUnit.level && (
              <div>Etasje: {props.parkingUnit.level}</div>
            )}
            {props.parkingUnit.length && (
              <div>Lengde: {props.parkingUnit.length}</div>
            )}
            {props.parkingUnit.width && (
              <div>Bredde: {props.parkingUnit.width}</div>
            )}
            {props.parkingUnit.price && (
              <div>
                Pris:{' '}
                {currency({
                  number: props.parkingUnit.price,
                  seperator: ' ',
                })}
                ,-
              </div>
            )}
            {props.parkingUnit.taxbase && (
              <div>
                Grunnlag for dokumentavgift:{' '}
                {currency({
                  number: props.parkingUnit.taxbase,
                  seperator: ' ',
                })}
              </div>
            )}
            {props.parkingUnit.estimatedTax && (
              <div>
                Estimert dokumentavgift:{' '}
                {currency({
                  number: props.parkingUnit.estimatedTax,
                  seperator: ' ',
                })}
              </div>
            )}
          </ExtraInfo>
          <ExtraInfo>
            {props.parkingUnit?.fasiliteter?.length > 0 && (
              <>
                <strong>Fasiliteter:</strong>
                <ul>
                  {props.parkingUnit.fasiliteter?.map((item: any) => {
                    let name = item.name;
                    if (name.match(/Garasje/)) {
                      name = 'Garasje / P-plass';
                    }
                    return <li key={`${item.name}_${item.value}`}>{name}</li>;
                  })}
                </ul>
              </>
            )}
          </ExtraInfo>
        </ExtraWrap>
      </td>
    </SubUnitWrap>
  );
};

export const Parking = (props: any) => {
  const estate = props.estate;

  const [parkingInfo, setParkingInfo] = useState<any>(null);

  const setUnit = (e: any) => {
    const id = e.currentTarget.dataset.pid;

    const unit = estate.parkingUnits.find(
      (p: ParkingUnit) => p.parkingId === id
    );
    if (parkingInfo?.parkingId === id) {
      return setParkingInfo(null);
    }

    setParkingInfo(unit);
  };

  const parkingUnits = estate.parkingUnits?.filter(
    (parkingUnit: ParkingUnit) => parkingUnit.estateId.length === 0
  );
  if (!parkingUnits || parkingUnits.length === 0) {
    return null;
  }

  return (
    <ParkingSection>
      <Title>Garasje/Parkeringsplass</Title>
      <div style={{ textAlign: 'center' }}>
        <p>
          Under vises de parkerings- og garasjeplasser som ikke er knyttet til
          en spesifikk boligenhet og&nbsp;kan&nbsp;kjøpes&nbsp;separat.
        </p>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Plass</th>
            <th className="hide-mobile-cell">Type</th>
            <th>Pris</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {parkingUnits.map((parkingUnit: ParkingUnit) => {
            return (
              <React.Fragment key={`parkering_${parkingUnit.parkingId}`}>
                <tr
                  onClick={setUnit}
                  data-pid={parkingUnit.parkingId}
                  key={parkingUnit.parkingId}
                >
                  <td>
                    <div>{parkingUnit.name}</div>
                  </td>
                  <td className="hide-mobile-cell">
                    <div>{parkingUnit.type_name}</div>
                  </td>
                  <td>
                    {currency({
                      number: Number(parkingUnit.price),
                      seperator: ' ',
                    })}
                  </td>
                  <td>{parkingUnit.status_name}</td>
                </tr>
                {parkingInfo?.parkingId === parkingUnit.parkingId && (
                  <Info parkingUnit={parkingInfo} />
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </ParkingSection>
  );
};

const ParkingSection = styled(Section)`
  max-width: 800px;
  height: auto;
  margin-top: 4em;
  font-size: 0.7em;
  @media (min-width: 800px) {
    font-size: 1em;
  }
`;
