import React from 'react';
import { Oppfordring } from './oppfordring';

export const Andelsbolig = () => {
  return (
    <div>
      <p>
        For andelsboliger er det borettslaget som er hjemmelshaver til selve
        eiendommen, Andelseiere har hjemmel til en andel i borettslaget og
        borett tilknyttet til denne, andelseiere er felles ansvarlige for
        borettslagets økonomi.
      </p>
      <p>
        <strong>Ansvar for øvrige andelseieres gjeld</strong>
        <br />
        Borettslagets inntekter består av andelseiernes månedlige
        felleskostnader. Dersom noen av andelseierne slutter å betale
        felleskostnader belaster dette borettslagets likviditet. Borettslaget
        vil i slike tilfeller kunne få rett til å tvangsselge den aktuelle
        andelen. Gjennom salget vil borettslaget kunne dekke sitt
        tilgodehavende. Inntil slikt tvangssalg er gjennomført vil mislighold av
        andelseieres betaling av felleskostnader kunne medføre økte
        felleskostnader for de øvrige andelseierne.
      </p>
      <p>
        Opplysninger som fremgår i denne salgsoppgaven er i hovedsak gitt av
        nåværende eier. Megler har også innhentet informasjon fra andre
        tilgjengelige kilder. Interessenter oppfordres til selv å foreta
        undersøkelser som kan ha betydning for eventuelle handlingsvalg
        forbundet med bud/kjøp av eiendommen.
      </p>
      <Oppfordring />
    </div>
  );
};
