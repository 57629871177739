import React, { useContext } from 'react';
import { Section, StandardButton } from '../globalstyles';
import { PageContext } from '../page';

export const GiBud = (props: { isTvangssalg: boolean }) => {
  const context = useContext(PageContext);
  const goTo = () => {
    if (props.isTvangssalg) {
      window.open(
        `https://digitalsalgsoppgave.garanti.no/api/file/view/616589f7-e7f8-41b3-86f3-cb81c95ee1ce`,
        '_blank',
        'noopener noreferrer'
      );
    } else {
      window.open(
        `https://bud.vitecnext.no/?Installationid=MSGAR&Estateid=${context.estate.estateId}`,
        '_blank',
        'noopener noreferrer'
      );
    }
  };

  return (
    <Section style={{ height: 'auto' }}>
      <StandardButton onClick={goTo}>Gi bud</StandardButton>
    </Section>
  );
};
