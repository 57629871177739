import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import { KeyVal } from './index';

export const PieChart = (props: { data: KeyVal[] }) => {
  const pieData = [...props.data];
  const pieDataSorted = pieData.sort((objA: KeyVal, objB: KeyVal) => {
    return Number(objA.value) - Number(objB.value);
  });

  const data: number[] = pieDataSorted.map((dataEl: KeyVal) =>
    Number(dataEl.value)
  );
  const labels = pieDataSorted.map((dataEl: KeyVal) => dataEl.name);

  const piechartRef = useRef(null);

  // Farger

  useEffect(() => {
    let ctx = null;
    let domElement: any = piechartRef.current;

    if (domElement) {
      ctx = domElement.getContext('2d');
    }
    new Chart(ctx, {
      type: 'pie',
      data: {
        datasets: [
          {
            data: data,
            backgroundColor: ['#70db9e', '#535353', '#b9b9b9', '#2fac66'],
          },
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels,
      },
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom',
        },
      },
    });
  }, []);

  return <canvas ref={piechartRef}></canvas>;
};
