import React, { useContext } from 'react';
import styled from 'styled-components';
import { Section, Title, Row } from '../globalstyles';
import { PageContext } from '../page';
import { SchemaTypes } from '../types';
import { BruktboligV1 } from './templates/bruktbolig';
import { TemplateV2 } from './templates/template';

export const Facts = () => {
  const context = useContext(PageContext);
  const meta = context.meta;

  return (
    <FactsSection color="#EDECE8">
      {meta.schema.id === SchemaTypes.default && <BruktboligV1 />}
      {meta.schema.id === SchemaTypes.prosjekt && <TemplateV2 />}
    </FactsSection>
  );
};

const FactsSection = styled(Section)`
  text-align: left;
  height: auto;
  background-color: var(--fact-background-color, #edece8);
`;
