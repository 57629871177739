import { ProjectUnit } from '../types';

export const apartmentNumberComparator = (a: ProjectUnit, b: ProjectUnit) => {
  const x = Number(a.sortApartmentNumber);
  const y = Number(b.sortApartmentNumber);

  if (!(isNaN(x) || isNaN(y))) {
    return SortNumber(x, y);
  }

  if (a.sortApartmentNumber > b.sortApartmentNumber) {
    return 1;
  }
  if (a.sortApartmentNumber < b.sortApartmentNumber) {
    return -1;
  }
  return 0;
};

const SortNumber = (a: number, b: number) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};
