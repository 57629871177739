import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

export const Portal = (props: { children: any }) => {
  const el = document.createElement('div');
  el.id = 'gallery';
  useEffect(() => {
    document.body.appendChild(el);

    return () => {
      document.body.removeChild(el);
    };
  });

  return ReactDOM.createPortal(props.children, el);
};
