import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { currency } from '../../utils/currency';

enum StatItems {
  ALDU_5 = 'ALDU_5',
  ALD6_12 = 'ALD6_12',
  ALD13_15 = 'ALD13_15',
  ALD16_18 = 'ALD16_18',
  ALD19_23 = 'ALD19_23',
  ALD24_34 = 'ALD24_34',
  ALD35_44 = 'ALD35_44',
  ALD45_54 = 'ALD45_54',
  ALD55_64 = 'ALD55_64',
  ALD65_74 = 'ALD65_74',
  ALD75_84 = 'ALD75_84',
  ALD85_O = 'ALD85_O',
  TOT_ANTA = 'TOT_ANTA',
  TOTAL_HUS = 'TOTAL_HUS',
}

const countPercent = (data: any, ageGroups: StatItems[]) => {
  const count = data
    .filter((obj: any) => ageGroups.indexOf(obj.type) > -1)
    .reduce((acc: number, cur: any) => acc + cur.percent, 0);
  return count;
};
const countValue = (data: any, ageGroups: StatItems[]) => {
  const count = data
    .filter((obj: any) => ageGroups.indexOf(obj.type) > -1)
    .reduce((acc: number, cur: any) => acc + cur.value, 0);
  return currency({ number: count, seperator: ' ' });
};

export const BarChart = (props: {
  data: { aldersfordeling: any; nabolag: any };
}) => {
  // Farger
  const nabolagDemografics = props.data.aldersfordeling.find(
    (data: any) => data.type === 'nabolag_demografics'
  );

  const nbDemo = nabolagDemografics.data.find(
    (data: any) => data.type === 'demografics'
  );

  const nabolag = nbDemo.data.find((data: any) => data.type === 'nabolag');
  const by = nbDemo.data.find((data: any) => data.type === 'by');
  const norge = nbDemo.data.find((data: any) => data.type === 'norge');

  const data = [];

  // 0 - 12
  data.push({
    name: 'Barn<br />(0-12)',
    values: [
      countPercent(nabolag.data, [StatItems.ALDU_5, StatItems.ALD6_12]),
      countPercent(by.data, [StatItems.ALDU_5, StatItems.ALD6_12]),
      countPercent(norge.data, [StatItems.ALDU_5, StatItems.ALD6_12]),
    ],
  });
  // 13 - 18
  data.push({
    name: 'Ungdom<br />(13-18)',
    values: [
      countPercent(nabolag.data, [StatItems.ALD13_15, StatItems.ALD16_18]),
      countPercent(by.data, [StatItems.ALD13_15, StatItems.ALD16_18]),
      countPercent(norge.data, [StatItems.ALD13_15, StatItems.ALD16_18]),
    ],
  });
  // 19 - 34
  data.push({
    name: 'Unge voksne<br />(19-34)',
    values: [
      countPercent(nabolag.data, [StatItems.ALD19_23, StatItems.ALD24_34]),
      countPercent(by.data, [StatItems.ALD19_23, StatItems.ALD24_34]),
      countPercent(norge.data, [StatItems.ALD19_23, StatItems.ALD24_34]),
    ],
  });
  // 35 - 64
  data.push({
    name: 'Voksne<br />(35-64)',
    values: [
      countPercent(nabolag.data, [
        StatItems.ALD35_44,
        StatItems.ALD45_54,
        StatItems.ALD55_64,
      ]),
      countPercent(by.data, [
        StatItems.ALD35_44,
        StatItems.ALD45_54,
        StatItems.ALD55_64,
      ]),
      countPercent(norge.data, [
        StatItems.ALD35_44,
        StatItems.ALD45_54,
        StatItems.ALD55_64,
      ]),
    ],
  });
  // over 65
  data.push({
    name: 'Eldre<br />(64 - )',
    values: [
      countPercent(nabolag.data, [
        StatItems.ALD65_74,
        StatItems.ALD75_84,
        StatItems.ALD85_O,
      ]),
      countPercent(by.data, [
        StatItems.ALD65_74,
        StatItems.ALD75_84,
        StatItems.ALD85_O,
      ]),
      countPercent(norge.data, [
        StatItems.ALD65_74,
        StatItems.ALD75_84,
        StatItems.ALD85_O,
      ]),
    ],
  });

  return (
    <>
      <BarWrapper>
        {data.map((group: any) => {
          return (
            <BarGroup key={`barGroup_${group.name}`}>
              <Bars>
                {group.values.map((value: number, i: number) => {
                  return (
                    <Bar key={`barValue_${group.name}_${i}`} height={value} />
                  );
                })}
              </Bars>
              <GroupName dangerouslySetInnerHTML={{ __html: group.name }} />
            </BarGroup>
          );
        })}
      </BarWrapper>

      <OmradeFordeling>
        <FakeTableColumn>
          <div>Område</div>
          <div>
            {props.data.nabolag.name} <ColorBox hex="#38ac68" />
          </div>
          <div>
            {props.data.nabolag.cityName} <ColorBox hex="#535353" />
          </div>
          <div>
            Norge <ColorBox hex="#b9b9b9" />
          </div>
        </FakeTableColumn>
        <FakeTableColumn>
          <div>Personer</div>
          <div>{countValue(nabolag.data, [StatItems.TOT_ANTA])}</div>
          <div>{countValue(by.data, [StatItems.TOT_ANTA])}</div>
          <div>{countValue(norge.data, [StatItems.TOT_ANTA])}</div>
        </FakeTableColumn>
        <FakeTableColumn>
          <div>Husholdninger</div>
          <div>{countValue(nabolag.data, [StatItems.TOTAL_HUS])}</div>
          <div>{countValue(by.data, [StatItems.TOTAL_HUS])}</div>
          <div>{countValue(norge.data, [StatItems.TOTAL_HUS])}</div>
        </FakeTableColumn>
      </OmradeFordeling>
    </>
  );
};

const BarWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-evenly;
  background-color: #fafafa;
  padding: 1em;
`;
const Bar = styled.div<{ height: number }>`
  position: relative;
  height: ${(props) => props.height * 1.6}%;
  width: 10px;
  margin: 0 3px 0 0;
  transition: background-color 0.1s ease-out;

  @media (min-width: 800px) {
    width: 20px;
  }

  &:before {
    content: '${(props) => Math.round(props.height)}%';
    position: absolute;
    font-size: 12px;
    padding: 5px;
    text-align: center;
    background-color: #ccc;
    z-index: 10;
    width: 20px;
    top: -40px;
    left: -5px;
    opacity: 0;
  }

  &:hover:before {
    display: block;
    opacity: 1;
  }

  &:nth-child(1) {
    background-color: #2fac66;
    &:hover {
      background-color: ${darken('0.1', '#2FAC66')};
    }
  }
  &:nth-child(2) {
    background-color: #535353;
    &:hover {
      background-color: ${darken('0.1', '#535353')};
    }
  }
  &:nth-child(3) {
    background-color: #b9b9b9;
    &:hover {
      background-color: ${darken('0.1', '#b9b9b9')};
    }
  }
`;

const BarGroup = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const Bars = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
`;

const GroupName = styled.div`
  font-size: 0.7em;
`;

const OmradeFordeling = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
`;
const FakeTableColumn = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    font-weight: 500;
  }
`;

const ColorBox = styled.div<{ hex: string }>`
  background-color: ${(props) => props.hex};
  width: 20px;
  height: 10px;
  display: inline-block;
  float: right;
  margin: 3px 0 0 10px;
  position: relative;
  top: 5px;

  @media (min-width: 800px) {
    top: 0;
    width: 50px;
    height: 20px;
  }
`;
