import styled, { createGlobalStyle } from "styled-components";
import { epIcons } from "./ep-icons-css";
import { flickitycss } from "./flickity-css";

export const GlobalStyle = createGlobalStyle`
  body{
    --maxWidth: 800px;
    --mq-desktop: 800px;
  }

  *{
    box-sizing: border-box;
  }

  body{
    margin: 0;
    padding: 0;
    font-family: 'Barlow Semi Condensed', sans-serif;
    line-height: 1.4;
    font-size: 18px; 

    &.remax {
      --blue: #003DA5;
      --red: #DC1C2E;
      --button-color: #003DA5;
      --button-border-color: #003DA5;
      --neighborhood-poi-background-color: #C4C6C8;
      --fact-background-color: #fff;
      --terms-background-color: #fff;
      font-family: 'Helvetica Neue Bold', sans-serif;
      
    }
  }

  strong{
    font-weight: 500;
  }

  .title{
    font-size: 2em;
    font-weight: 400;
  }

  @font-face {
    font-family: ep;
    src: url("/static/ep.ttf");
    font-weight: bold;
  }

  .hide-mobile, .hide-mobile-cell {
    display: none;
  }

  @media (min-width: 800px) {
    .hide-mobile{
      display: block;
    }
    .hide-mobile-cell {
      display: table-cell;
    }
  }

  ${flickitycss}
  ${epIcons}

`;

export const Section = styled.div<{
  image?: string;
  darken?: boolean;
  color?: string;
  backgroundSize?: string;
}>`
  height: 100vh;
  background-color: ${(props) => (props.color ? props.color : "#fff")};
  ${(props) => props.image && `background-image: url(${props.image});`}
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(props) =>
    props.backgroundSize ? props.backgroundSize : "cover"};
  z-index: 1;
  position: relative;
  padding: 3rem 2rem;
  margin: 0 auto;

  &:before {
    ${(props) =>
      props.darken &&
      `position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      height: 100vh;
      background-color: rgba(0,0,0,0.8);
    `}
  }

  &.terms {
    background-color: var(--terms-background-color, #e6e7e2);
  }
`;

export const Title = styled.div`
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  margin: 0 0 3rem;
`;

export const Title2 = styled.div`
  font-size: 1.6em;
  font-weight: 400;
  text-align: left;
  margin: 0 0 1rem;
`;

export const TitleUpper = styled.div`
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4px 0 0;
  div:first-child {
    font-weight: 500;
  }
  div:nth-child(2) {
    text-align: right;
  }
`;

export enum ButtonType {
  "normal",
  "filled",
}

export const Button = styled.button<{ buttonType?: ButtonType }>`
  border: none;
  background: transparent;
  padding: 5px 10px 7px;
  font-family: inherit;
  color: #ddd;
  border: 2px solid #666;
  display: block;
  cursor: pointer;

  ${(props) =>
    props.buttonType === ButtonType.filled &&
    `background: #666; margin-right: 1em;`}
`;

export const Content = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
`;

export const StandardButton = styled(Button)`
  color: #fff;
  width: 100%;
  max-width: 300px;
  border-color: var(--button-border-color, #000);
  font-size: 1em;
  margin: 0 auto;
  background-color: var(--button-color, #000);

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 800px;
  margin: 1em auto 0;
  width: 100%;
  justify-content: center;

  button:first-child {
    margin-left: 0;
  }
  button:last-child {
    margin-right: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr {
    cursor: pointer;
  }

  th,
  td {
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
    text-align: center;
    border-bottom: 1px solid #000;
    padding: 10px;
    text-align: left;
  }
`;

export const SubUnitWrap = styled.tr`
  td {
    background-color: #e6e7e2;
  }
  .images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;

    img {
      width: 100%;
    }
    .loadergif {
      width: 50px;
      padding: 1em 0;
    }
  }
  .subUnitImg {
    float: left;
  }
`;

export const ExtraWrap = styled.div`
  padding: 1em;
  line-height: 1.6;
  display: block;
  @media (min-width: 800px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ExtraInfo = styled.div`
  text-align: left;
  margin-bottom: 1em;
  @media (min-width: 800px) {
    &:nth-child(2) {
      text-align: right;
    }
    margin-bottom: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
  }
`;

export const FrontSection = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoadingText = styled.div`
  font-size: 1.6em;
  margin: 1em 0 0;
  text-align: center;
`;
