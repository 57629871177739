import React from 'react';
import styled from 'styled-components';
import Flickity from 'react-flickity-component';

export interface Image {
  id: string;
  src: string;
  alt?: string;
}

export const FlickityGallery = (props: { images: Image[], descOpacity?: string }) => {
  const flickityOptions = {
    lazyLoad: 2,
    fade: true,
  };
  return (
    <GalleryWrap descOpacity={props.descOpacity} >
      <Flickity
        className={'carousel'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
      >
        {props.images.map((img: Image) => {
          return (
            <div className="imgwrap" key={img.id}>
              <img data-flickity-lazyload={img.src} />
              {img.alt && <div className="imgdescription">{img.alt}</div>}
            </div>
          );
        })}
      </Flickity>
      )
    </GalleryWrap>
  );
};

const GalleryWrap = styled.div<{
  descOpacity?: string;
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 2;

  .imgwrap {
    height: 100%;
    width: 100%;
  }

  .imgdescription {
    z-index: 2;
    background-color: black;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    padding: 1em;
    ${(props) => props.descOpacity && `opacity: ${props.descOpacity};`}
  }

  img {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    object-fit: contain;
    font-family: 'object-fit: contain;';
  }
`;
