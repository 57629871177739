import { EstateTypes, Ownership } from '../types';

import React from 'react';
import { Aksjebolig } from '../content/aksjebolig';
import { Andelsbolig } from '../content/andelsbolig';
import { Eierseksjon } from '../content/eierseksjon';
import { FastEiendom } from '../content/fasteiendom';
import { Kontraktsposisjon } from '../content/kontraktsposisjon';

export const getVilkaar = (type: Ownership | EstateTypes) => {
  switch (type) {
    case Ownership.Aksje:
      return <Aksjebolig />;
    case Ownership.Andel:
      return <Andelsbolig />;
    case Ownership.Eiet:
      return <FastEiendom />;
    case Ownership.Eierseksjon:
      return <Eierseksjon />;
    case Ownership.Obligasjon:
      return <Aksjebolig />;
    case EstateTypes.Prosjekt:
      return <Kontraktsposisjon />;
    default:
      <FastEiendom />;
  }
};
