import React, { useContext } from 'react';
import styled from 'styled-components';
import { Section, Title } from '../globalstyles';
import { PageContext } from '../page';
import { StandardButtons } from './standard-buttons';

export const Heading = () => {
  const context = useContext(PageContext);
  const estate = context.estate;

  return (
    <HeadingSection>
      <Flex>
        <CustomTitle>{estate.address.street}</CustomTitle>
        <p>{estate.heading}</p>
        <StandardButtons />
      </Flex>
    </HeadingSection>
  );
};

const HeadingSection = styled(Section)`
  height: 500px;

  @media (min-width: 800px) {
    height: 300;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  max-width: var(--max-width);
`;

const CustomTitle = styled(Title)`
  margin-bottom: 1rem;
`;
