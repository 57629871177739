import React, { useEffect, useState } from "react";
import { Brands, getClassName } from "../utils/brands";
import styled from "styled-components";

interface IArgs {
  className?: string;
  brand?: Brands | null;
}

export const LogoLite = ({ className, brand }: IArgs) => {
  if (!brand) return null;
  const ShowLogo = () => {
    switch (brand) {
      case Brands.REMAX:
        return true;
      default:
        return false;
    }
  };
  if (!ShowLogo()) return null;
  return (
    <Flex>
      <Logo
        className={`${className ?? ``} ${getClassName(brand) ?? ``}`?.trim()}
      />
    </Flex>
  );
};

const Logo = styled.div`
  width: 2em;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;

  &.remax {
    width: 4em;
    aspect-ratio: 280 / 240;
    background-image: url("/static/remax-logo-lite.png");
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
`;
