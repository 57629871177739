import React, { useContext } from "react";
import styled from "styled-components";
import { Content, Section, Title, Title2 } from "../globalstyles";
import { PageContext } from "../page";
import { BarChart } from "./barchart";
import { PieChart } from "./piechart";
import { PoiList } from "./poilist";
import { Ratings } from "./rating";

export interface KeyVal {
  name: string;
  value: string;
}

export interface IPoi {
  poiId: string;
  poitypeId: string;
  name: string;
  distances: any;
  duration: any;
}

export interface IPoiGroup {
  name: string;
  pois: IPoi[];
}

export const Nabolagsprofil = () => {
  const context = useContext(PageContext);
  const nabolag = context.estate.neighbourhood?.nabolag;
  const boligmasse: KeyVal[] =
    context.estate.neighbourhood?.demographics.boligmasse;
  const aldersfordeling = context.estate.neighbourhood?.statistics;
  const ratings = nabolag?.ratings;

  if (!context.estate.neighbourhood) return null;

  return (
    <>
      {nabolag && (
        <NeigbourhoodSection>
          <Content>
            <Title>Nabolagsprofil</Title>
            {ratings ? <Ratings ratings={ratings} /> : null}
            {nabolag?.recommendedfor ? (
              <RecommendedFor>
                <Title2>Spesielt anbefalt for:</Title2>
                {nabolag.recommendedfor.map(
                  (recommendedfor: { name: string }) => {
                    return (
                      <li key={`recommended_${recommendedfor.name}`}>
                        {recommendedfor.name}
                      </li>
                    );
                  }
                )}
              </RecommendedFor>
            ) : null}
            {boligmasse ? (
              <Boligmasse>
                <Title2>Boligmasse</Title2>
                <PieChart data={boligmasse} />
              </Boligmasse>
            ) : null}
            {aldersfordeling && nabolag ? (
              <Aldersfordeling>
                <Title2>Aldersfordeling</Title2>
                <BarChart
                  data={{ aldersfordeling: aldersfordeling, nabolag: nabolag }}
                />
              </Aldersfordeling>
            ) : null}
          </Content>
        </NeigbourhoodSection>
      )}
      <PoiList />
    </>
  );
};

const NeigbourhoodSection = styled(Section)`
  min-height: 100vh;
  height: auto;
  background-color: var(--neighborhood-background-color, #fff);
`;

const RecommendedFor = styled.ul`
  margin: 2em 0;
  padding: 0;
  width: 50%;
  float: left;
  li {
    font-size: 1.3em;
    color: #38ac68;
    list-style: none;
    font-weight: 500;
    padding: 0;
  }
`;

const Boligmasse = styled.div`
  float: left;
  margin: 2em 0;
  width: 100%;

  @media (min-width: 800px) {
    width: 50%;
  }
`;

const Aldersfordeling = styled.div`
  clear: both;
  margin: 2em 0;
`;
