import { useQuery } from '@apollo/client';
import React from 'react';
import { getPricelist } from '../graphql/queries/getPricelist';
import { Pricelist } from './pricelist';
import { Parking } from './pricelist/parking';

export const PricelistPage = (props: { match: any }) => {
  const { data, loading, error } = useQuery(getPricelist, {
    variables: {
      input: {
        estateId: props.match.params.id,
        version: 0,
        create_preview: false,
        neighbourhood: false,
        doRefresh: false
      },
    },
  });
  if (loading) {
    return null;
  }
  if (error) {
    return null;
  }

  const estate = data.getEstate.estate;
  return (
    <div>
      <Pricelist estate={estate} />
      <Parking estate={estate} />
    </div>
  );
};
