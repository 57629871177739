import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Approve } from './approve';
import { AuthContextProvider } from './context/Authentication';
import { Front } from './front';
import { GlobalStyle } from './globalstyles';
import { Page } from './page';
import { PricelistPage } from './pricelist-page';

export const Main = () => {
  return (
    <AuthContextProvider>
      <GlobalStyle />
      <Switch>
        <Route exact path="/" component={Front} />
        <Route exact path="/preview/:id/:page(public)" component={Page} />
        <Route exact path="/preview/:id/godkjennelse" component={Approve} />
        <Route exact path="/:id/:version(\d+)" component={Page} />
        <Route exact path="/:id/prislistevedlegg" component={PricelistPage} />
      </Switch>
    </AuthContextProvider>
  );
};
