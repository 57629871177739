import React from 'react';
import styled from 'styled-components';
import { FrontSection, LoadingText } from './globalstyles';

export const LoaderGif = (props: any) => {
  return <img {...props} src="/static/loadergaranti.gif" alt="Loader" />;
};

export const Loader = (props: { text: string }) => {
  return (
    <FrontSection>
      <LoaderGif width="50" style={{ margin: '2em 0 0' }} />
      <LoadingText>{props.text}</LoadingText>
    </FrontSection>
  );
};
