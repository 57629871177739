import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Main } from '../common';
import { getClient } from './apollo-client';

const client = getClient();

const App = (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </ApolloProvider>
);

const hasNodes = document.getElementById('app')?.hasChildNodes();
if (hasNodes) {
  ReactDOM.hydrate(App, document.getElementById('app'));
} else {
  ReactDOM.render(App, document.getElementById('app'));
}
