import React, { useContext } from 'react';
import styled from 'styled-components';
import { IBuildingArea } from './types';
import { Row } from '../../globalstyles';

interface IArgs {
  area: IBuildingArea;
}

export const BuildingData = ({ area }: IArgs) => {
  const { type, description: typeDesc, data } = area ?? {};
  if (!data?.length) return null;
  return (
    <>
      {data?.map((data, index) => {
        const { description, floor, size } = data ?? {};
        if (typeof size !== 'number') return null;
        const title = `${typeDesc ?? `Ukjent`} ${floor} etasje:`.trim();
        return (
          <Row key={`d${index}`} style={{ margin: 0 }}>
            <div>
              {title}
              {description?.length ? <StyledData>{description}</StyledData> : null}
            </div>
            <div>{size}&nbsp;m&sup2;</div>
          </Row>
        );
      })}
    </>
  );
};

const StyledData = styled.div`
  font-weight: normal !important;
`;
