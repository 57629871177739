import React, { useState } from 'react';
import styled from 'styled-components';
import { currency } from '../../utils/currency';
import { Section, Table, Title } from '../globalstyles';
import { StandardButtons } from '../heading/standard-buttons';
import { apartmentNumberComparator } from '../keyinfo/apartmentNumberComparator';
import { SubUnit } from '../keyinfo/subUnit';
import { EstateStatus, ProjectUnit } from '../types';

export const Pricelist = (props: any) => {
  const estate = props.estate;
  const filterStatus = [EstateStatus.Sold, EstateStatus.Request];
  const [showInfo, setShowInfo] = useState<any>(null);

  const setInfo = (e: any) => {
    const id = e.currentTarget.dataset.id;
    const unit = estate.projectUnits.find((unit: any) => unit.estateId === id);
    if (showInfo?.estateId === id) {
      return setShowInfo(null);
    }
    return setShowInfo(unit);
  };

  const getStatus = (status: EstateStatus) => {
    switch (status) {
      case EstateStatus.Sold:
        return 'Solgt';
      case EstateStatus.Reserved:
        return 'Reservert';
      default:
        return 'Ledig';
    }
  };

  let sortableList: any = [];

  // Ikke tell med solgte, de blir filtrert ut
  if (
    estate.projectUnits?.filter(
      (unit: ProjectUnit) => !filterStatus.includes(unit.status)
    ).length > 0
  ) {
    sortableList = [...estate.projectUnits];
  }

  return (
    <PricelistSection>
      <StandardButtons />
      <div className="sub-unit-info">
        <Title>Prisliste for {estate.address.address}</Title>
        <Table>
          <thead>
            <tr>
              <th>Leil.nr</th>
              <th>Pris</th>
              <th className="hide-mobile-cell">Fellesgjeld</th>
              <th className="hide-mobile-cell">Felleskost. pr. mnd.</th>
              <th>Totalpris</th>
              <th className="hide-mobile-cell">Parkering</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortableList &&
              sortableList
                .filter((unit: ProjectUnit) => !filterStatus.includes(unit.status))
                .sort(apartmentNumberComparator)
                .map((unit: ProjectUnit, i: number) => {
                  const status = getStatus(unit.status);
                  return (
                    <React.Fragment key={`${unit.address}_${i}`}>
                      <tr onClick={setInfo} data-id={unit.estateId}>
                        <td>{unit.sortApartmentNumber}</td>
                        <td>
                          {currency({
                            number: unit.price.priceSuggestion,
                            seperator: ' '
                          })}
                          ,-
                        </td>
                        <td className="hide-mobile-cell">
                          {unit.price.collectiveDebt &&
                            `
                        ${currency({
                          number: unit.price.collectiveDebt,
                          seperator: ' '
                        })},-`}
                        </td>
                        <td className="hide-mobile-cell">
                          {unit.price.rentPrMonth &&
                            `
                        ${currency({
                          number: unit.price.rentPrMonth,
                          seperator: ' '
                        })},-`}
                        </td>
                        <td>
                          {unit.price.totalPrice &&
                            `
                        ${currency({
                          number: unit.price.totalPrice,
                          seperator: ' '
                        })},-`}
                        </td>
                        <td className="hide-mobile-cell">
                          {unit.parkingUnits?.length > 0 ? 'Ja' : 'Nei'}
                        </td>
                        <td>{status}</td>
                      </tr>
                      {showInfo && unit.estateId === showInfo.estateId && (
                        <SubUnit showInfo={showInfo} />
                      )}
                    </React.Fragment>
                  );
                })}
          </tbody>
        </Table>
      </div>
    </PricelistSection>
  );
};

const PricelistSection = styled(Section)`
  height: auto;
  max-width: 800px;

  .sub-unit-info {
    margin-top: 4em;
    font-size: 0.7em;
    @media (min-width: 800px) {
      font-size: 1em;
    }
  }
`;
