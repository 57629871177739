import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Images } from '../../graphql/types';
import { Portal } from '../../utils/portal';
import { Section } from '../globalstyles';
import { PageContext } from '../page';
import { FlickityGallery, Image } from './flickity';
import { FloorPlanGallery } from './floorplan';

export const Gallery = () => {
  const context = useContext(PageContext);

  const [showGallery, setGallery] = useState(false);

  const toggleGallery = () => {
    setGallery(!showGallery);
  };
  const estate = context.estate;

  const images = useMemo(() => {
    const images = context.estate.images.map((img: any) => {
      return {
        id: img.imageId,
        src: `/images?img=${encodeURIComponent(img.url)}`,
        alt: img.imageDescription,
      };
    });
    return images;
  }, [estate.images]);

  return (
    <>
      {!context.isPrint && (
        <FlexSection
          image={`/images?img=${encodeURIComponent(
            estate.images.find(
              (img: Images) => img.ai_label !== 'plantegning' // finn første som ikke er bilde nummer 1 og som ikke er plantegning
            )?.url
          )}`}
          style={{ height: '50vh', minHeight: '500px' }}
        >
          <GalleriButton onClick={toggleGallery} />
        </FlexSection>
      )}
      {showGallery && (
        <Portal>
          <Close onClick={toggleGallery} />
          <FlickityGallery images={images} />
        </Portal>
      )}
      {context.isPrint &&
        images.map((img: Image) => {
          return (
            <PrintImage key={`print_${img.id}`}>
              <img src={img.src} alt="" />
              <PrintDesc>{img.alt}</PrintDesc>
            </PrintImage>
          );
        })}
    </>
  );
};

export const FlexSection = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GalleriButton = styled.div`
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background-image: url(/static/gallery.png);
  background-position: center;
  background-size: 60px;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const Close = styled.div`
  background-size: 24px;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 0;
  z-index: 3;
  color: #000;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url(/static/close.svg);
  background-repeat: no-repeat;
  background-position: center;
`;

const PrintImage = styled.div`
  img {
    width: 100%;
    object-fit: contain;
  }
`;
const PrintDesc = styled.div`
  text-align: center;
  margin: 10px 0 15px;
`;
