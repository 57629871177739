import React from "react";
import styled from "styled-components";
import { Section, Title } from "../globalstyles";

export enum BGColor {
  "main",
  "secondary",
}

export const Content = (props: {
  title: string;
  bgColor: BGColor;
  children: any;
  className?: string;
}) => {
  return (
    <ContentSection className={props?.className} bg={props.bgColor}>
      <TextContent>
        <Title>{props.title}</Title>
        {props.children}
      </TextContent>
    </ContentSection>
  );
};

const ContentSection = styled(Section)<{ bg: BGColor }>`
  background-color: ${(props) =>
    props.bg === BGColor.secondary ? "#e6e7e2" : "#fff"};
  height: auto;
`;

const TextContent = styled.div`
  margin: 0 auto;
  max-width: var(--maxWidth);
`;
