import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { LoaderGif } from '../loader';
import { ProjectUnit, ParkingUnit } from '../types';
import { currency } from '../../utils/currency';
import { ExtraInfo, ExtraWrap, SubUnitWrap } from '../globalstyles';
import { ValidData } from '../../utils/utils';

const Plantegning = (props: { img: HTMLImageElement }) => {
  const openImage = (e: any) => {
    window.open(e.currentTarget.dataset.src, '_blank', 'noopener');
  };

  return (
    <div
      className="subUnitImg"
      onClick={openImage}
      data-src={`/images?img=${encodeURIComponent(props.img.src)}`}
      key={`/images?img=${encodeURIComponent(props.img.src)}`}
    >
      <img width="300" src={`/images?img=${encodeURIComponent(props.img.src)}`} />
    </div>
  );
};

export const SubUnit = (props: { showInfo: ProjectUnit }) => {
  const [loaded, setLoaded] = useState(0);
  const [images, setImages] = useState<HTMLImageElement[]>([]);

  const springProps = useSpring({
    config: { delay: 300 },
    opacity: loaded ? 1 : 0
  });

  useEffect(() => {
    setLoaded(0);
    const images = props.showInfo.images
      .filter((img: any) => img.ai_label === 'plantegning' && img.ai_percent > 95)
      .map((img: any) => {
        const image: any = new Image();
        image.onload = () => {
          setLoaded(loaded + 1);
        };
        image.src = `/images?img=${encodeURIComponent(img.url)}`;
        return image;
      });
    setImages(images);
  }, [props.showInfo.estateId]);

  return (
    <SubUnitWrap>
      <td colSpan={7}>
        <ExtraWrap>
          <ExtraInfo>
            <strong>Info om eiendommen</strong>

            {props.showInfo.price.priceSuggestion &&
            ValidData(props.showInfo.price.priceSuggestion) ? (
              <div>
                Pris:{' '}
                {currency({
                  number: props.showInfo.price.priceSuggestion,
                  seperator: ' '
                })}
                ,-
              </div>
            ) : null}
            {props.showInfo.price.totalPrice &&
            ValidData(props.showInfo.price.totalPrice) ? (
              <div>
                Pris:{' '}
                {currency({
                  number: props.showInfo.price.totalPrice,
                  seperator: ' '
                })}
                ,-
              </div>
            ) : null}
            {props.showInfo.price.purchaseCostsAmount &&
            ValidData(props.showInfo.price.purchaseCostsAmount) ? (
              <div>
                Kjøpers omkostninger:{' '}
                {currency({
                  number: props.showInfo.price.purchaseCostsAmount,
                  seperator: ' '
                })}
                ,-
              </div>
            ) : null}
            {props.showInfo.price.collectiveDebt &&
            ValidData(props.showInfo.price.collectiveDebt) ? (
              <div>
                Fellesgjeld:{' '}
                {currency({
                  number: props.showInfo.price.collectiveDebt,
                  seperator: ' '
                })}
                ,-
              </div>
            ) : null}
            {props.showInfo.price.rentPrMonth &&
            ValidData(props.showInfo.price.rentPrMonth) ? (
              <div>
                Felleskostnader pr. mnd.:{' '}
                {currency({
                  number: props.showInfo.price.rentPrMonth,
                  seperator: ' '
                })}
                ,-
              </div>
            ) : null}
            {props.showInfo.primaryRoomArea &&
            !ValidData(props?.showInfo?.size?.usableInternalArea) &&
            ValidData(props.showInfo.primaryRoomArea) ? (
              <div>P-rom: {props.showInfo.primaryRoomArea}m&sup2;</div>
            ) : null}
            {props.showInfo.size.usableArea &&
            ValidData(props.showInfo.size.usableArea) ? (
              <div>BRA: {props.showInfo.size.usableArea}m&sup2;</div>
            ) : null}

            {props?.showInfo?.size?.usableInternalArea &&
            ValidData(props?.showInfo?.size?.usableInternalArea) ? (
              <div>
                BRA-I (internt bruksareal): {props?.showInfo?.size?.usableInternalArea}
                m&sup2;
              </div>
            ) : null}
            {props?.showInfo?.size?.usableExternalArea &&
            ValidData(props?.showInfo?.size?.usableExternalArea) ? (
              <div>
                BRA-E (eksternt bruksareal): {props?.showInfo?.size?.usableExternalArea}
                m&sup2;
              </div>
            ) : null}
            {props?.showInfo?.size?.usableEnclosedBalconyArea &&
            ValidData(props?.showInfo?.size?.usableEnclosedBalconyArea) ? (
              <div>
                BRA-B (innglasset balkong):{' '}
                {props?.showInfo?.size?.usableEnclosedBalconyArea}m&sup2;
              </div>
            ) : null}
            {props?.showInfo?.size?.openArea &&
            ValidData(props?.showInfo?.size?.openArea) ? (
              <div>
                TBA (terrasse-/balkongareal): {props?.showInfo?.size?.openArea}
                m&sup2;
              </div>
            ) : null}
            {props.showInfo.size.grossArea && ValidData(props.showInfo.size.grossArea) ? (
              <div>BTA: {props.showInfo.size.grossArea}m&sup2;</div>
            ) : null}

            {props.showInfo.floor && ValidData(props.showInfo.floor) ? (
              <div>BTA: {props.showInfo.floor}m&sup2;</div>
            ) : null}
            {props.showInfo.noOfRooms && ValidData(props.showInfo.noOfRooms) ? (
              <div>Antall rom: {props.showInfo.noOfRooms}</div>
            ) : null}
            {props.showInfo.noOfBedRooms && ValidData(props.showInfo.noOfBedRooms) ? (
              <div>Antall soverom: {props.showInfo.noOfBedRooms}</div>
            ) : null}
          </ExtraInfo>
          {props.showInfo.parkingUnits ? (
            <ExtraInfo>
              {props.showInfo.parkingUnits?.map((parkingUnit: ParkingUnit) => {
                return (
                  <div key={parkingUnit.parkingId}>
                    <strong>Parkering</strong>
                    {parkingUnit.name && <div>Plass-navn: {parkingUnit.name}</div>}
                    {parkingUnit.type_name && <div>Type: {parkingUnit.type_name}</div>}
                    {parkingUnit.price && (
                      <div>
                        Pris:{' '}
                        {currency({
                          number: parkingUnit.price,
                          seperator: ' '
                        })}
                        ,-
                      </div>
                    )}
                    {parkingUnit.taxbase && (
                      <div>Grunnlag for dokumentavgift: {parkingUnit.taxbase}</div>
                    )}
                    {parkingUnit.estimatedTax && (
                      <div>Estimert dokumentavgift: {parkingUnit.estimatedTax}</div>
                    )}
                    {parkingUnit.status_name && (
                      <div>Status: {parkingUnit.status_name}</div>
                    )}
                    {parkingUnit.level && <div>Etasje: {parkingUnit.level}</div>}
                    {parkingUnit.width && <div>Bredde: {parkingUnit.width}</div>}
                    {parkingUnit.length && <div>Lengde: {parkingUnit.length}</div>}
                    {parkingUnit?.fasiliteter?.length > 0 && (
                      <>
                        <strong>Fasiliteter:</strong>
                        <ul>
                          {parkingUnit.fasiliteter?.map((fasilitet: any) => {
                            return (
                              <li key={`${fasilitet.name}_${fasilitet.value}`}>
                                {fasilitet.name}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </div>
                );
              })}
            </ExtraInfo>
          ) : null}
        </ExtraWrap>
        <div className="images">
          {images.length > 0 && !loaded ? <LoaderGif className="loadergif" /> : ''}
          {images.length > 0
            ? images.map((img: HTMLImageElement, i: number) => (
                <animated.div key={`img_plantegning_${i}`} style={springProps}>
                  <Plantegning img={img} />
                </animated.div>
              ))
            : null}
        </div>
      </td>
    </SubUnitWrap>
  );
};
