import React from 'react';
import { Oppfordring } from './oppfordring';

export const Eierseksjon = () => {
  return (
    <div>
      <p>
        Eierseksjoner kalles ofte selveierleiligheter. Her er seksjonseier
        sameier i hele bygningen og tomten, videre er det knyttet en enerett
        (eksklusiv bruksrett) til bestemte deler av eiendommen (seksjonen og
        eventuelle tilleggsdeler). Forholdet sameierne imellom er regulert i
        eierseksjonsloven. Øverste myndighet i et eierseksjons-sameie utøves av
        sameiemøte. Viktige dokumenter å gjøre seg kjent med ved kjøp av
        eierseksjon er vedtekster, husordensregler, regnskap, budsjett og
        protokoll fra sameiemøte.
      </p>
      <p>
        Opplysninger som fremgår i denne salgsoppgaven er i hovedsak gitt av
        nåværende eier. Megler har også innhentet informasjon fra andre
        tilgjengelige kilder. Interessenter oppfordres til selv å foreta
        undersøkelser som kan ha betydning for eventuelle handlingsvalg
        forbundet med bud/kjøp av eiendommen.
      </p>
      <Oppfordring />
    </div>
  );
};
