import React from 'react';
import { Oppfordring } from './oppfordring';

export const Aksjebolig = () => {
  return (
    <div>
      <p>
        For aksjeboliger er det aksjeselskapet som er hjemmelshaver til selve
        eiendommen. Aksjene i selskapet gir leierett til en bestemt bolig.
        Aksjonærene er felles ansvarlige for borettslagets økonomi.
      </p>
      <p>
        <strong>Ansvar for øvrige aksjonærers gjeld</strong>
        <br />
        Boligselskapets inntekter består av aksjonærenes månedlige
        felleskostnader. Dersom noen av aksjonærene slutter å betale
        felleskostnader belaster dette selskapets likviditet. Selskapet vil i
        slike tilfeller kunne få rett til å tvangsselge den aktuelle aksjen med
        tilknyttet leierett til bolig. Gjennom salget vil selskapet kunne dekke
        sitt tilgodehavende. Inntil slikt tvangssalg er gjennomført vil
        mislighold av aksjonærenes betaling av felleskostnader kunne medføre
        økte felleskostnader for de øvrige aksjonærene.
      </p>
      <p>
        Opplysninger som fremgår i denne salgsoppgaven er i hovedsak gitt av
        nåværende eier. Megler har også innhentet informasjon fra andre
        tilgjengelige kilder. Interessenter oppfordres til selv å foreta
        undersøkelser som kan ha betydning for eventulle handlingsvalg forbundet
        med bud/kjøp av eiendommen.
      </p>
      <Oppfordring />
    </div>
  );
};
